import React from "react";
import lax from "lax.js";
import LaxDiv from "../Shared/LaxDiv";
import api from "../../services/api";
import MaskedInput from "react-text-mask";
import Select from "react-select";

import './Register.scss';

class About extends React.Component {
  state = {
    formFields: {},
    lgpd: false,
    submitted: false,
    submitError: false,
    selectedOptions: [],
    lang: 'pt-br',
    interestList: [
      {label: "Management Services", value: "Management Services"},
      {label: "Food & beverage", value: "Food & beverage"},
      {label: "Storage", value: "Storage"},
      {label: "Architecture & Construction", value: "Architecture & Construction"},
      {label: "Aviation", value: "Aviation"},
      {label: "Trade and international development", value: "Trade and international development"},
      {label: "Defense and Airspace", value: "Defense and Airspace"},
      {label: "Bank and Investments", value: "Bank and Investments"},
      {label: "Hospitality", value: "Hospitality"},
      {label: "Internet services", value: "Internet services"},
      {label: "Tourism, leisure and travels", value: "Tourism, leisure and travels"},
      {label: "Airlines", value: "Airlines"},
      {label: "Marketing", value: "Marketing"},
      {label: "Others", value: "Others"}
    ]
  };

  constructor(props) {
    super(props);
    lax.setup();

    document.addEventListener(
      "scroll",
      function (x) {
        lax.update(window.scrollY);
      },
      false
    );

    lax.update(window.scrollY);
  }

  onInputChange = selected => {
    let arr = [];
    this.setState({ selectedOptions: selected });

    for (let i = 0; i < selected.length; i++) {
      const element = selected[i];
      arr.push(element.value);
    }

    if(selected.length === 0){
      arr = [];
    }

    this.setState({
      formFields: {
        ...this.state.formFields,
        "interesses": arr,
        origin: "hotsite",
      },
    })
  };

  handleForm = async (e) => {
    e.preventDefault();

    if (this.props.global.lgpd && !this.state.lgpd && this.state.lang === 'en') {
      return this.setState({ submitError: "You must accept the terms!" });
    }

    if (this.props.global.lgpd && !this.state.lgpd) {
      return this.setState({ submitError: "É necessário aceitar os termos!" });
    }

    if (
      this.state.formFields.convite &&
      this.state.formFields.convite !== "R2BPARTNER"
    ) {
      return this.setState({ submitError: "Código convite inválido" });
    }

    this.setState({ submitError: false });

    let user = { ...this.state.formFields };

    user.extra = {};

    Object.entries(user).forEach((item) => {
      if (
        item[0] !== "name" &&
        item[0] !== "email" &&
        item[0] !== "password" &&
        item[0] !== "extra"
      ) {
        user.extra[item[0]] = item[1];
        delete user[item[0]];
      }
    });

    user._id = user.email;
    if (user.extra && user.extra.telefone)
      user.extra.telefone = user.extra.telefone.replace(/[^\d]+/g, "");

    if (user.extra && user.extra.cpf)
      user.extra.cpf = user.extra.cpf.replace(/[^\d]+/g, "");

    await api.put("database/registration__c", user, {
      headers: {
        Authorization:
          "Basic " +
          window.btoa(
            process.env.REACT_APP_APIKEY +
              ":" +
              process.env.REACT_APP_PUBLIC_SECRETKEY
          ),
      },
    });

    this.setState({ submitted: true });
    if(this.state.lang === 'pt-br') {
      document.getElementById("contactForm").reset();
    } else if(this.state.lang === 'en') {
      document.getElementById("contactFormEn").reset();
    }
  };

  onHideSuccess = () => {
    this.setState({ submitted: false });
  };

  successMessage = () => {
    let successMessageTxt = '';
    if (this.state.lang === 'pt-br') {
      successMessageTxt = this.props.global.register.successMessage ? this.props.global.register.successMessage : 'Inscrição realizada com sucesso!';
    } else if (this.state.lang === 'en') {
      successMessageTxt = this.props.global.register.successMessage ? this.props.global.register.successMessage : 'Registration successful!';
    }
    if (this.state.submitted) {
      return (
        <div
          className="alert alert-success alert-dismissible fade show"
          style={{ marginTop: "14px" }}
        >
          <span dangerouslySetInnerHTML={{ __html: successMessageTxt }}></span>
          <button type="button" className="close" onClick={this.onHideSuccess}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      );
    }
  };

  errorMessage = () => {
    if (this.state.submitError) {
      return (
        <div
          className="alert alert-danger  alert-dismissible fade show"
          style={{ marginTop: "14px" }}
        >
          {this.state.submitError}
          <button type="button" className="close" onClick={this.onHideSuccess}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      );
    }
  };

  setlang = (lang) => {
    this.setState({ lang: lang });
    this.setState({
      formFields: {
        ...this.state.formFields,
        lang: lang,
      },
    })
  };

  render() {
    return (
      <section id="enrollment" className="pricing-area ptb-120 bg-image">
        <div className="container">
          <div className="section-title">
            <h2 className="titleMain">Inscreva-se</h2>
            <span className="subTitleMain">Garanta a sua vaga</span>

            <LaxDiv text="Inscreva-se" dataPreset="driftLeft" />

            <div className="bar"></div>
          </div>
          <div className="mx-auto col-lg-8 col-md-12">
            <div className="buttons">
              <button className="btn btn-primary" onClick={() => { this.setlang('pt-br')} } style={{padding: "10px"}}>
                <img src={require("../../assets/images/br.png")} alt="" style={{width: "50px"}}/>
              </button>
              <button className="btn btn-primary" onClick={() => { this.setlang('en')} } style={{padding: "10px"}}>
                <img src={require("../../assets/images/en.png")} alt="" style={{width: "50px"}}/>
              </button>
            </div>
            {this.props.global.register.email && !this.props.global.register.button && this.state.lang === 'pt-br' &&
              <form onSubmit={this.handleForm} id="contactForm">
                <label className="mb-3">{this.props.global.txtInscricao}</label>

                <div className="row">
                  {this.props.global.register.attributes.map((item) =>
                    item.name !== "login" &&
                    item.name !== "password" &&
                    item.name !== "telefone" &&
                    item.name !== "cpf" &&
                    item.type !== "select" ? (
                      <div key={item.name} className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <label htmlFor={item.name}>
                            {item.title}
                            {item.required ? "*" : ""}
                          </label>
                          <input
                            type={item.type}
                            className="form-control"
                            name={item.name}
                            id={item.name}
                            onChange={(e) =>
                              this.setState({
                                formFields: {
                                  ...this.state.formFields,
                                  [item.name]: e.target.value,
                                },
                              })
                            }
                            required={item.required}
                            data-error="Please enter your name"
                          />
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                    ) : item.name === "telefone" ? (
                      <div key={item.name} className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <label htmlFor={item.name}>
                            {item.title}
                            {item.required ? "*" : ""}
                          </label>
                          <MaskedInput
                            guide={false}
                            keepCharPositions={false}
                            showMask={false}
                            mask={[
                              "(",
                              /\d/,
                              /\d/,
                              ")",
                              " ",
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                              "-",
                              /\d/,
                              /\d/,
                              /\d/,
                              /\d/,
                            ]}
                            type={item.type}
                            className="form-control"
                            name={item.name}
                            id={item.name}
                            onChange={(e) =>
                              this.setState({
                                formFields: {
                                  ...this.state.formFields,
                                  [item.name]: e.target.value,
                                },
                              })
                            }
                            required={item.required}
                          />
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                    ) : item.name === "cpf" ? (
                      <div key={item.name} className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <label htmlFor={item.name}>
                            {item.title}
                            {item.required ? "*" : ""}
                          </label>
                          <MaskedInput
                            guide={false}
                            keepCharPositions={false}
                            showMask={false}
                            mask={[
                              /\d/,
                              /\d/,
                              /\d/,
                              ".",
                              /\d/,
                              /\d/,
                              /\d/,
                              ".",
                              /\d/,
                              /\d/,
                              /\d/,
                              "-",
                              /\d/,
                              /\d/
                            ]}
                            type={item.type}
                            className="form-control"
                            name={item.name}
                            id={item.name}
                            onChange={(e) =>
                              this.setState({
                                formFields: {
                                  ...this.state.formFields,
                                  [item.name]: e.target.value,
                                },
                              })
                            }
                            required={item.required}
                          />
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                    ) : item.type === "select" ? (
                      <div key={item.name} className="col-lg-12 col-md-12">
                        <div className="form-group">
                          <label htmlFor={item.name}>
                            {item.title}
                            {item.required ? "*" : ""}
                          </label>
                          <select
                            required={item.required}
                            className="form-control"
                            onChange={(e) =>
                              this.setState({
                                formFields: {
                                  ...this.state.formFields,
                                  [item.name]: e.target.value,
                                },
                              })
                            }
                          >
                            <option value="">Selecione uma opção</option>
                            {item.static_dropdown.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                          <div className="help-block with-errors"></div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )
                  )}

                  {this.props.global.interestList.length > 0 && (
                    <div className="col-lg-12 col-md-12 mb-3">
                      <div className="form-group">
                        <label htmlFor="interesses">
                          {!!this.props.global.nameInterestList ? this.props.global.nameInterestList : 'Interesses'}
                        </label>
                        <div style={{zIndex: "2", position: "relative"}}>
                          <Select
                            options={this.props.global.interestList}
                            isMulti
                            isClearable
                            placeholder={'Selecione'}
                            onChange={this.onInputChange}
                          />
                        </div>
                      </div>
                    </div>
                  )}

                  {this.props.global.lgpd && (
                    <div className="col-lg-12 col-md-12 mb-3">
                      <div className="form-check">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="check"
                          onChange={(e) =>
                            this.setState({ lgpd: e.target.checked })
                          }
                        />
                        <label className="form-check-label" htmlFor="check">
                          {this.props.global.lgpdText} <br />
                          <a
                            href={this.props.global.lgpdFile.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Termos e política de privacidade
                          </a>
                        </label>
                      </div>
                    </div>
                  )}

                  {/* {this.props.global.lgpd && (
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <input
                          type="checkbox"
                          id="exampleCheck1"
                        />
                        <label class="form-check-label" for="exampleCheck1">
                          Check me out
                        </label>
                        <div className="help-block with-errors"></div>
                      </div>
                    </div>
                  )} */}

                  {/* <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <label htmlFor="email">Email*</label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        onChange={(e) =>
                          this.setState({
                            formFields: {
                              ...this.state.formFields,
                              email: e.target.value,
                            },
                          })
                        }
                        data-error="Please enter your email"
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <label htmlFor="number">Telefone*</label>
                      <input
                        type="text"
                        className="form-control"
                        name="number"
                        id="number"
                        required={true}
                        onChange={(e) =>
                          this.setState({
                            formFields: {
                              ...this.state.formFields,
                              phone: e.target.value,
                            },
                          })
                        }
                        data-error="Please enter your number"
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <label htmlFor="name">Empresa*</label>
                      <input
                        type="text"
                        className="form-control"
                        name="empresa"
                        id="empresa"
                        onChange={(e) =>
                          this.setState({
                            formFields: {
                              ...this.state.formFields,
                              empresa: e.target.value,
                            },
                          })
                        }
                        data-error="Please enter your name"
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <label htmlFor="name">Cargo*</label>
                      <input
                        type="text"
                        className="form-control"
                        name="cargo"
                        id="cargo"
                        data-error="Please enter your name"
                        onChange={(e) =>
                          this.setState({
                            formFields: {
                              ...this.state.formFields,
                              cargo: e.target.value,
                            },
                          })
                        }
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div>

                  <div className="col-lg-12 col-md-12">
                    <div className="form-group">
                      <label htmlFor="name">Código convite</label>
                      <input
                        type="text"
                        className="form-control"
                        name="codigo"
                        id="codigo"
                      />
                      <div className="help-block with-errors"></div>
                    </div>
                  </div> */}

                  <div className="col-lg-12 col-md-12">
                    <button type="submit" className="btn">
                      Enviar
                    </button>
                    <div id="msgSubmit" className="h3 text-center">
                      {this.successMessage()}
                    </div>
                    <div id="msgSubmit" className="h3 text-center">
                      {this.errorMessage()}
                    </div>
                    <div className="clearfix"></div>
                  </div>
                </div>
              </form>
            }
            {this.props.global.register.email && !this.props.global.register.button && this.state.lang === 'en' &&
              <form onSubmit={this.handleForm} id="contactFormEn">
                <label className="mb-3"></label>
                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label htmlFor="identification"> Identification Number (passport / local ID)*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="identification"
                      id="identification"
                      data-error=""
                      required={true}
                      onChange={(e) =>
                        this.setState({
                          formFields: {
                            ...this.state.formFields,
                            identification: e.target.value,
                          },
                        })
                      }
                    />
                    <div className="help-block with-errors"></div>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label htmlFor="fullname"> Full Name*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      id="name"
                      data-error=""
                      required={true}
                      onChange={(e) =>
                        this.setState({
                          formFields: {
                            ...this.state.formFields,
                            name: e.target.value,
                          },
                        })
                      }
                    />
                    <div className="help-block with-errors"></div>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label htmlFor="telephonenumber"> Telephone number*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="telephonenumber"
                      id="telephonenumber"
                      data-error=""
                      required={true}
                      onChange={(e) =>
                        this.setState({
                          formFields: {
                            ...this.state.formFields,
                            telephonenumber: e.target.value,
                          },
                        })
                      }
                    />
                    <div className="help-block with-errors"></div>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label htmlFor="email"> E-mail*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="email"
                      id="email"
                      data-error=""
                      required={true}
                      onChange={(e) =>
                        this.setState({
                          formFields: {
                            ...this.state.formFields,
                            email: e.target.value,
                          },
                        })
                      }
                    />
                    <div className="help-block with-errors"></div>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label htmlFor="company"> Company / Institution*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="company"
                      id="company"
                      data-error=""
                      required={true}
                      onChange={(e) =>
                        this.setState({
                          formFields: {
                            ...this.state.formFields,
                            company: e.target.value,
                          },
                        })
                      }
                    />
                    <div className="help-block with-errors"></div>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label htmlFor="position"> Position*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="position"
                      id="position"
                      data-error=""
                      required={true}
                      onChange={(e) =>
                        this.setState({
                          formFields: {
                            ...this.state.formFields,
                            position: e.target.value,
                          },
                        })
                      }
                    />
                    <div className="help-block with-errors"></div>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label htmlFor="country"> Country*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="country"
                      id="country"
                      data-error=""
                      required={true}
                      onChange={(e) =>
                        this.setState({
                          formFields: {
                            ...this.state.formFields,
                            country: e.target.value,
                          },
                        })
                      }
                    />
                    <div className="help-block with-errors"></div>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label htmlFor="city"> City*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="city"
                      id="city"
                      data-error=""
                      required={true}
                      onChange={(e) =>
                        this.setState({
                          formFields: {
                            ...this.state.formFields,
                            city: e.target.value,
                          },
                        })
                      }
                    />
                    <div className="help-block with-errors"></div>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12">
                  <div className="form-group">
                    <label htmlFor="interesses">
                      Sectors of Interest
                    </label>
                    <div style={{zIndex: "2", position: "relative"}}>
                      <Select
                        options={this.state.interestList}
                        isMulti
                        isClearable
                        placeholder={'Selecione'}
                        onChange={this.onInputChange}
                      />
                    </div>
                  </div>
                </div>

                {this.props.global.lgpd && (
                  <div className="col-lg-12 col-md-12 mb-3">
                    <div className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="check2"
                        onChange={(e) =>
                          this.setState({ lgpd: e.target.checked })
                        }
                      />
                      <label className="form-check-label" htmlFor="check2">
                        By submitting this form you declare that you are aware of Aneaa's Terms of Use and Privacy Policy <br />
                        <a
                          href={this.props.global.lgpdFile.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms and Privacy Policy
                        </a>
                      </label>
                    </div>
                  </div>
                )}

                <div className="col-lg-12 col-md-12">
                  <button type="submit" className="btn">
                    Register
                  </button>
                  <div id="msgSubmit" className="h3 text-center">
                    {this.successMessage()}
                  </div>
                  <div id="msgSubmit" className="h3 text-center">
                    {this.errorMessage()}
                  </div>
                  <div className="clearfix"></div>
                </div>
              </form>
            }
          </div>
          {!this.props.global.register.email && this.props.global.register.button && 
            <div>
              <a type="button" className="btn" href={this.props.global.register.linkRegistrationButton} target="_blank" rel="noopener noreferrer">
                {this.props.global.register.textRegistrationButton}
              </a>
            </div>
          }
        </div>
      </section>
    );
  }
}

export default About;
